import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { signUserOut } from '../../../../actions/user';

import logoBlancBleu from '../../../../assets/img/smart-logo-512x512.png';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 300px;
  height: 40px;
  width: 100%;
  background-image: url(${logoBlancBleu});
  background-position: 20px 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${props => props.theme.colors.blue};
`;

const User = styled.div`
  float: right;
`;

const UserName = styled.div`
  margin: 5px 80px 5px 0;
  height: 30px;
  line-height: 30px;
  color: ${props => props.theme.colors.white};
`;

const UserAction = styled.div`
  float: right;
  display: block;
  margin: 0 20px 0;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
`;

const BtnUserSignOut = styled.button`
  width: 40px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

class TopBar extends Component {
  signUserOut = () => {
    const { dispatch } = this.props;
    dispatch(signUserOut());
  };

  render() {
    const { user } = this.props;

    return (
      <Wrapper>
        <User>
          <UserAction>
            <BtnUserSignOut onClick={this.signUserOut}>
              <Icon name="power off" size="large" />
            </BtnUserSignOut>
          </UserAction>
          <UserName>{`${user.sPrenom} ${user.sNom[0]}.`}</UserName>
        </User>
      </Wrapper>
    );
  }
}

TopBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.objectOf(PropTypes.any)])
  ).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.data,
  signOutError: state.user.error
});

export default connect(mapStateToProps)(TopBar);
