import {
  INTERVENANT_LOAD_SUCCESS,
  INTERVENANT_LOAD_FAILURE,
  INTERVENANT_MEETING_LOAD_SUCCESS,
  INTERVENANT_MEETING_LOAD_FAILURE,
  INTERVENANT_PATIENT_SUCCESS,
  INTERVENANT_PATIENT_FAILURE
} from '../constants/ActionTypes';

const initialRequestState = {
  list: []
};

export default (state = initialRequestState, { type, payload }) => {
  switch (type) {
    case INTERVENANT_LOAD_SUCCESS: {
      return { ...state, list: payload };
    }
    case INTERVENANT_LOAD_FAILURE: {
      return { ...state, list: [], error: payload };
    }
    case INTERVENANT_MEETING_LOAD_SUCCESS: {
      return { ...state, meetings: payload };
    }
    case INTERVENANT_MEETING_LOAD_FAILURE: {
      return { ...state, meetings: [], error: payload };
    }
    case INTERVENANT_PATIENT_SUCCESS: {
      return { ...state, search: payload };
    }
    case INTERVENANT_PATIENT_FAILURE: {
      return { ...state, search: payload };
    }
    default:
      return state;
  }
};
