import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import Routes from './Routes';

import themes from './themes';

import UniversCondensed from './assets/fonts/Univers-Condensed.otf';
import UniversCondensedBold from './assets/fonts/Univers-Condensed-Bold.otf';
import Futura from './assets/fonts/Futura-LT-Book.otf';
import marker from './assets/img/marker-icon.svg';

import 'semantic-ui-css/semantic.min.css';
import 'react-reflex/styles.css';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  @keyframes adsBounce {
    from{margin-top:0px;}
    to{margin-top:-10px;}
  }

  .bounce {
    animation-name: adsBounce;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes colorblink {
    from { }
    to { background:${props => props.theme.colors.purple}; }
  }

  .blink {
    animation: colorblink 1s infinite;
  }

  .ui.fullscreen.modal {
    margin-left: 2.5% !important;
  }

  .map-label {
    mask: url(${marker}) no-repeat center;
    height: 30px !important;
    width: 17px !important;
  }

  .ui.form .fields {
    margin: 0 0 1em !important;
  }

  .ui.checkbox input:checked~.box:before,
  .ui.checkbox input:checked~label:before {
    background-color: ${props => props.theme.colors.blue} !important;
    color: ${props => props.theme.colors.textBlack} !important;
  }

  .field .ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox input:checked~label,
  .field .ui.toggle.checkbox .box, .ui.toggle.checkbox label {
    color: ${props => props.theme.colors.textBlack} !important;
  }

  .field .ui.toggle.checkbox .box:before,
  .field .ui.toggle.checkbox label:before {
    background-color: ${props => props.theme.colors.grey} !important;
  }

  .field .ui.toggle.checkbox input:focus:checked~.box:before,
  .field .ui.toggle.checkbox input:focus:checked~label:before,
  .field .ui.toggle.checkbox input:checked~.box:before,
  .field .ui.toggle.checkbox input:checked~label:before {
    background-color: ${props => props.theme.colors.blue} !important;
    color: ${props => props.theme.colors.textBlack} !important;
  }

  * {
      box-sizing: border-box
  }

  html {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.backgroundLighter};

  }

  body {
    margin: 0;
   padding: 0;
    min-width: ${window.innerWidth}px;
    min-height: ${window.innerHeight}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "UniversCondensed", sans-serif !important;
    font-size: 14px;
    line-height: 1.2857142857142857142857142857143em;
    color: ${props => props.theme.colors.textBlack} !important;
    background: ${props => props.theme.colors.backgroundLighter} !important;
    overscroll-behavior: contain;
  }

  h1, h2, h3, h4,
  strong {
    font-family: "UniversCondensedBold";
  }

  label,
  input,
  textarea {
    font-family: "Futura", sans-serif !important;
  }

  .ui.button {
    font-family: "Futura", sans-serif !important;
    font-weight: 400 !important;
  }

  a {
    color: ${props => props.theme.colors.blue};

    &:focus,
    &:visited {
      color: ${props => props.theme.colors.blueDark};
      outline: none;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .footer {

  border-top: 2px solid ${props => props.theme.colors.inputBorderDark};
  }

    .smart-selected {
    background-color: ${props => props.theme.colors.orange} !important;
    color: #ffffff !important;

    &.smart-today {
      color:#ffffff !important;
    }
  }

  .smart-today {
      color: ${props => props.theme.colors.orange} !important;
      font-weight: bold;
  }
`;

const theme = themes[process.env.REACT_APP_THEME]
  ? themes[process.env.REACT_APP_THEME]
  : themes.ads;

const App = ({ history }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <Router>
        <Routes history={history} animatedTransitions />
      </Router>
      <GlobalStyle />
    </React.Fragment>
  </ThemeProvider>
);

App.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(App);
