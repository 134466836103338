import { urlBase64ToUint8Array } from '../../utils';

const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;

export async function subscribe(user) {
  return;
  // Register Service Worker
  const register = await navigator.serviceWorker.register('/notificationsServiceWorker.js', {
    scope: '.'
  });

  await navigator.serviceWorker.ready;

  // Register Push
  const subscription = await register.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    })
    .catch(error => {
      if (Notification.permission === 'denied') {
        console.warn('Permission for Notifications was denied');
      } else {
        console.error('Unable to subscribe to push.', error);
      }
    });

  if (!localStorage.subscription) {
    localStorage.subscription = JSON.stringify(subscription);

    await fetch(`${process.env.REACT_APP_NOTIFICATIONS_SERVER_URL}/subscribe`, {
      method: 'POST',
      body: JSON.stringify({ subscription, user: { id: user.iPKUtilisateur } }),
      headers: {
        'content-type': 'application/json'
      }
    });
  }
}
// Register SW, Register Push, Send Push
export async function send(iPKAnnuaire) {
  // Send Push Notification
  console.log('Sending Push...');
  await fetch(`${process.env.REACT_APP_NOTIFICATIONS_SERVER_URL}/newmessage`, {
    method: 'POST',
    body: JSON.stringify({ userId: iPKAnnuaire }),
    headers: {
      'content-type': 'application/json'
    }
  });
  console.log('Push Sent...');
}

export function foo() {}
