import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import jwt from 'jsonwebtoken';

import { setAuthorizationToken } from '../utils';
import { client, fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILURE,
  USER_LOGGED_REQUEST,
  USER_LOGGED_FAILURE,
  USER_UNLOGGED_FAILURE,
  USER_UNLOGGED_REQUEST
} from '../constants/ActionTypes';

export function* signUserIn({ payload: { sLogin, sPassword } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'login' } });

  try {
    const AUTORISE = gql`{
        autoriseUtilisateur(sLogin: "${sLogin}", sPassword: "${sPassword}") {
          iPKUtilisateur
          sCodeCivilite
          sLogin
          sNom
          sPrenom
        }
      }`;
    const response = yield call(fetchApollo, AUTORISE);
    const user = response.data.autoriseUtilisateur;

    if (user !== null) {
      user.sPassword = sPassword;
      user.dDate = new Date();

      const token = jwt.sign(user, process.env.REACT_APP_JWT_SECRET, {
        expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN
      });

      setAuthorizationToken(token);
      localStorage.setItem('jwtToken', JSON.stringify(token));
      localStorage.setItem('user', JSON.stringify(user));
      yield put({
        type: USER_LOGGED_REQUEST,
        payload: { iPKAnnuaire: user.iPKUtilisateur }
      });
      yield put({ type: USER_SIGNIN_SUCCESS, payload: user });
    } else {
      localStorage.removeItem('jwtToken');

      yield put({ type: USER_SIGNIN_FAILURE, payload: 'Identifiants incorrects' });
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'login' } });
    }
  } catch ({ status, message }) {
    let msg = '';

    switch (status) {
      case 500:
        msg = 'Internal Server Error';
        break;
      case 404:
        msg = 'Page not found';
        break;
      case 401:
        msg = 'Identifiants incorrects';
        break;
      default:
        msg = message;
    }

    localStorage.removeItem('jwtToken');
    yield put({ type: USER_SIGNIN_FAILURE, payload: msg });
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'login' } });
  }
}

export function* signUserOut() {
  if (localStorage.getItem('jwtToken')) {
    localStorage.removeItem('jwtToken');
    // client.resetStore();
    const user = JSON.parse(localStorage.getItem('user'));
    yield put({
      type: USER_SIGNOUT_SUCCESS,
      payload: {}
    });
    yield put({
      type: USER_UNLOGGED_REQUEST,
      payload: { iPKAnnuaire: user.iPKUtilisateur }
    });
  } else {
    client.resetStore();

    yield put({
      type: USER_SIGNOUT_FAILURE,
      payload: {}
    });
  }
}

export function* setUserUnlogged({ payload: { iPKAnnuaire } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`mutation {
        setUserUnlogged(
          iPKAnnuaire: ${iPKAnnuaire}
        )
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.receivedMessage;
    if (requests === null) {
      yield put({
        type: USER_UNLOGGED_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: USER_UNLOGGED_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* setUserLogged({ payload: { iPKAnnuaire } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`mutation {
        setUserLogged(
          iPKAnnuaire: ${iPKAnnuaire}
        )
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.receivedMessage;
    if (requests === null) {
      yield put({
        type: USER_LOGGED_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: USER_LOGGED_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}
