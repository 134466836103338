import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Comment, Form, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { DateToDuration } from '../../utils';
import truckImg from '../../assets/img/truck.svg';
import operatorImg from '../../assets/img/operator.svg';
import chat from '../../assets/img/chat.svg';
import { sendMessage, readMessage, loadChat, desactiveConversation } from '../../actions/chat';
import { send } from '../Notification/Notification';
import Submit from '../Core/Layout/Btn/Submit';

const StyledChat = styled.div`
  position: fixed;
  left: 0;
  bottom: 5px;
  z-index: 999;
  display: inline-block;
  margin: 0 0 0 5px;
`;

const StyledContentChat = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  padding: 5px;
  border: 1px solid ${props => props.theme.colors.inputBorderDark};
  max-height: 400px;
  overflow: auto;
  background: ${props => props.theme.colors.white};
`;

const StyledResponse = styled(Comment)`
  margin: 0 0 10px;

  & .avatar {
    float: right !important;
  }
  & .content {
    margin-right: 3.5em;
    margin-left: 0 !important;
  }
`;

const StyledButton = styled.button`
  position: relative;
  display: block;
  padding: 0 30px 0 10px;
  height: 40px;
  width: 270px;
  line-height: 40px;
  text-align: left;
  font-family: 'Futura';
  cursor: pointer;
  color: white;
  background-color: ${props =>
    props.visible ? props.theme.colors.blueDark : props.theme.colors.textBlack};
  border: 0;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.colors.blueDark};
  }

  & img {
    position: relative;
    top: 3px;
    display: inline-block;
    margin: 0 10px 0 0;
    width: 20px;
  }
`;

const StyledComment = styled(Comment)`
  margin: 0 0 10px;
  & .avatar {
    float: right !important;
  }
  & .content {
    margin-right: 3.5em;
    margin-left: 0 !important;
  }
`;

const StyledSendButton = styled(Submit)`
  background-color: ${props => props.theme.colorPrimary} !important;
  color: white;
  &:hover {
    background-color: ${props => props.theme.colorPrimaryActive} !important;
  }
`;

const StytledTextArea = styled(Form.TextArea)`
  height: 50px !important;
  margin: 0 0 20px !important;
`;

const BtnViewItemClose = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: transparent;
  border: 0;
  outline: none;
  transition: all ease-in-out 0.25s

  &:hover {
    color: ${props => props.theme.colorPrimary};
    background: ${props => props.theme.colorPrimaryActive};
  }

  & i.icon {
    margin: 0 !important;
  }
`;

class SmartChat extends Component {
  state = {
    visible: false
  };

  delayReadMessage = {};

  delayLoadMessage = {};

  chatRefs = [];

  componentDidUpdate(prevProps) {
    const { tConversation } = prevProps;
    const { dispatch, user } = this.props;
    const { visible } = this.state;
    tConversation.map(oConversation => {
      const objDiv = document.getElementById(
        `content-chat-${oConversation.iFKAnnAnnuaireIntervenant}`
      );
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      return null;
    });
    if (typeof visible === 'object') {
      const iFKAnnAnnuaireIntervenant = parseInt(Object.keys(visible)[0], 10);
      // Send the message is readed if conversation stay open 3 seconds
      this.delayReadMessage[iFKAnnAnnuaireIntervenant] = setTimeout(() => {
        dispatch(readMessage(iFKAnnAnnuaireIntervenant, user.iPKUtilisateur));
      }, parseInt(process.env.REACT_APP_DELAY_SEND_MESSAGE_READED, 0));
    }
  }

  close = (e, oConversation) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    dispatch(desactiveConversation(oConversation.iFKAnnAnnuaireIntervenant));
    clearInterval(this.delayLoadMessage[oConversation.iFKAnnAnnuaireIntervenant]);
    this.setState({
      visible: {
        [oConversation.iFKAnnAnnuaireIntervenant]: false
      }
    });
  };

  show = oConversation => {
    const { visible } = this.state;
    const { dispatch, tConversation } = this.props;
    tConversation.map(conversation => {
      clearInterval(this.delayLoadMessage[conversation.iFKAnnAnnuaireIntervenant]);
      return null;
    });
    if (oConversation.tMessage.length > 0) {
      if (
        typeof visible[oConversation.iFKAnnAnnuaireIntervenant] === 'undefined' ||
        visible[oConversation.iFKAnnAnnuaireIntervenant] === false
      ) {
        // Reload all messages in the opened conversation
        dispatch(
          loadChat(oConversation.iFKAnnAnnuaireSuperviseur, oConversation.iFKAnnAnnuaireIntervenant)
        );
        // Send the message is readed if conversation stay open 3 seconds
        this.delayReadMessage[oConversation.iFKAnnAnnuaireIntervenant] = setTimeout(() => {
          dispatch(
            readMessage(
              oConversation.iFKAnnAnnuaireIntervenant,
              oConversation.iFKAnnAnnuaireSuperviseur
            )
          );
        }, parseInt(process.env.REACT_APP_DELAY_SEND_MESSAGE_READED, 0));
        // Reload all messages in the opened conversation all the 5 seconds
        this.delayLoadMessage[oConversation.iFKAnnAnnuaireIntervenant] = setInterval(() => {
          dispatch(
            loadChat(
              oConversation.iFKAnnAnnuaireSuperviseur,
              oConversation.iFKAnnAnnuaireIntervenant
            )
          );
        }, parseInt(process.env.REACT_APP_DELAY_LOAD_MESSAGE, 0));
      } else {
        clearTimeout(this.delayReadMessage[oConversation.iFKAnnAnnuaireIntervenant]);
        clearInterval(this.delayLoadMessage[oConversation.iFKAnnAnnuaireIntervenant]);
      }
    } else {
      clearInterval(this.delayLoadMessage[oConversation.iFKAnnAnnuaireIntervenant]);
    }

    this.setState({
      visible: {
        [oConversation.iFKAnnAnnuaireIntervenant]: !visible[oConversation.iFKAnnAnnuaireIntervenant]
      }
    });
  };

  handleChange = (e, { value, name }) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = destinataire => {
    const { dispatch, user } = this.props;
    if (this.state[`message-${destinataire}`]) {
      dispatch(
        sendMessage(user.iPKUtilisateur, destinataire, this.state[`message-${destinataire}`])
      );
      // Check for service worker
      if ('serviceWorker' in navigator) {
        send(destinataire).catch(err => console.error(err));
      }
      this.setState({
        [`message-${destinataire}`]: ''
      });
    }
  };

  render() {
    const { visible } = this.state;
    const { tConversation } = this.props;
    let unreadMessage = false;
    const maxElement = Math.floor(window.innerWidth / 275);
    let i = -1;
    return (
      <>
        {tConversation.map(oConversation => {
          if (oConversation.active) {
            i += 1;
            let left;
            if (i >= maxElement) {
              left = 275 * (i - maxElement);
            } else {
              left = 275 * i;
            }
            oConversation.tMessage.forEach(message => {
              if (
                message.iFKAnnAnnuaireDestinataire ===
                parseInt(oConversation.iFKAnnAnnuaireSuperviseur, 0)
              ) {
                if (!message.bLu) {
                  unreadMessage = true;
                }
              }
            });
            if (oConversation.tMessage.length === 0) {
              unreadMessage = false;
            }
            return (
              <StyledChat
                style={{ left }}
                id={`chat-${oConversation.iFKAnnAnnuaireIntervenant}`}
                key={`chat-${oConversation.iFKAnnAnnuaireIntervenant}`}
              >
                <StyledButton
                  className={unreadMessage ? 'blink' : ''}
                  onClick={() => this.show(oConversation)}
                  visible={visible[oConversation.iFKAnnAnnuaireIntervenant]}
                >
                  <img src={chat} alt="chat" />
                  {oConversation.sNomPrenomIntervenant}
                  <BtnViewItemClose onClick={e => this.close(e, oConversation)}>
                    <Icon name="close" />
                  </BtnViewItemClose>
                </StyledButton>
                <StyledContentChat
                  id={`content-chat-${oConversation.iFKAnnAnnuaireIntervenant}`}
                  visible={visible[oConversation.iFKAnnAnnuaireIntervenant]}
                >
                  {oConversation.tMessage.map(message => {
                    if (
                      message.iFKAnnAnnuaireAuteur ===
                      parseInt(oConversation.iFKAnnAnnuaireIntervenant, 0)
                    ) {
                      return (
                        <StyledComment key={`comment-${message.iPKSmaMessage}`}>
                          <Comment.Avatar width={30} src={truckImg} />
                          <Comment.Content>
                            <Comment.Metadata>
                              Reçus {`${DateToDuration(message.dhEnvoi)} :`}
                            </Comment.Metadata>
                            <Comment.Text>{message.sTexteMessage}</Comment.Text>
                          </Comment.Content>
                        </StyledComment>
                      );
                    }
                    return (
                      <StyledResponse key={`response-${message.iPKSmaMessage}`}>
                        <Comment.Avatar width={30} src={operatorImg} />
                        <Comment.Content>
                          <Comment.Metadata>
                            <div>
                              Envoyé {DateToDuration(message.dhEnvoi)}{' '}
                              {message.bRecu && !message.bLu ? (
                                <span style={{ color: 'green' }}>Reçu</span>
                              ) : null}
                              {message.bLu ? <span style={{ color: 'green' }}>Lu</span> : null}
                            </div>
                          </Comment.Metadata>
                          <Comment.Text>{message.sTexteMessage}</Comment.Text>
                        </Comment.Content>
                      </StyledResponse>
                    );
                  })}
                  <Form style={{ textAlign: 'center' }}>
                    <StytledTextArea
                      name={`message-${oConversation.iFKAnnAnnuaireIntervenant}`}
                      value={this.state[`message-${oConversation.iFKAnnAnnuaireIntervenant}`]}
                      onChange={this.handleChange}
                      rows={2}
                    />
                    <StyledSendButton
                      onClick={() => this.handleSubmit(oConversation.iFKAnnAnnuaireIntervenant)}
                      label="Envoyer"
                    />
                  </Form>
                </StyledContentChat>
              </StyledChat>
            );
          }
          return null;
        })}
      </>
    );
  }
}

SmartChat.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tConversation: PropTypes.arrayOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

SmartChat.defaultProps = {
  tConversation: []
};

const mapStateToProps = state => ({
  tConversation: state.chat.tConversation,
  user: state.user.data
});

export default connect(mapStateToProps)(SmartChat);
