import {
  VIEW_LIST_REQUEST,
  VIEW_ADD_REQUEST,
  VIEW_EDIT_REQUEST,
  VIEW_DELETE_REQUEST,
  VIEW_ACTIVATE_REQUEST,
  VIEW_DEACTIVATE_REQUEST
} from '../constants/ActionTypes';

export const listViews = iPKUtilisateur => ({
  type: VIEW_LIST_REQUEST,
  payload: { iPKUtilisateur }
});

export const addView = (iPKAnnuaire, sLabel, tPos) => ({
  type: VIEW_ADD_REQUEST,
  payload: { iPKAnnuaire, sLabel, tPos }
});

export const editView = (iPKVue, sLabel, oFilters) => ({
  type: VIEW_EDIT_REQUEST,
  payload: { iPKVue, sLabel, oFilters }
});

export const deleteView = (iPKVue, iPKUtilisateur) => ({
  type: VIEW_DELETE_REQUEST,
  payload: { iPKVue, iPKUtilisateur }
});

export const activateView = view => ({
  type: VIEW_ACTIVATE_REQUEST,
  payload: view
});

export const deactivateView = () => ({
  type: VIEW_DEACTIVATE_REQUEST,
  payload: {}
});
