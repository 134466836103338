import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';

import { List, Button, Icon, Form, Input, Modal, Grid } from 'semantic-ui-react';
import smallMarker from '../../assets/img/marker-icon-small.svg';

import Submit from '../Core/Layout/Btn/Submit';
import AdsMap from '../Map/Map';

import { loadIntervenantsByPatient } from '../../actions/intervenant';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  margin-bottom: 10px;
  padding: 20px;
  background: ${props => props.theme.colors.tableRowSelected};
`;

const StyledList = styled(List)`
  margin: 0 !important;
`;

const StyledEtatItemLeft = styled(List.Item)`
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${props => props.theme.colors.blueDark};
  }
  :after {
    content: '';
    mask: url(${smallMarker}) no-repeat center;
    height: 25px !important;
    width: 16px !important;
    background-color: ${props => props.color};
    height: 100% !important;
    top: 0;
    right: 60px;
    visibility: visible !important;
    width: 10px;
    position: absolute;
  }
  .content {
    width: 90%;
    padding: 15px 20px !important;
  }
`;

const BtnIsadomLeft = styled(Button)`
  position: absolute;
  top: 6px;
  right: 8px;
`;

const Label = styled.label`
  display: block !important;
  margin: 0 0 10px !important;
  width: 100% !important;
  color: rgba(0, 0, 0, 0.95) !important;
`;

class LocateIntervenant extends Component {
  state = {
    hour: DateTime.local()
      .toLocaleString(DateTime.TIME_SIMPLE)
      .split(':')[0],
    searched: false
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { code, hour } = this.state;
    if (code && hour) {
      this.setState(
        {
          searched: true
        },
        () => {
          dispatch(loadIntervenantsByPatient(code, parseInt(hour, 0)));
        }
      );
    }
  };

  render() {
    const { hour, searched } = this.state;
    const { tSearchedIntervenant, modalOpen, handleClose, config } = this.props;
    const intervenant = [];
    return (
      <Modal open={modalOpen} onClose={handleClose} size="fullscreen" closeIcon dimmer="inverted">
        <Modal.Header>Localiser un intervenant près d&apos;un patient</Modal.Header>
        <Modal.Content>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={3}>
                <Wrapper>
                  <Form>
                    <Form.Group inline>
                      <Form.Field
                        control={Input}
                        onChange={this.handleChange}
                        label={<Label>Code patient :</Label>}
                        name="code"
                      />
                    </Form.Group>
                    <Label>Autour de (une heure avant et une après) : </Label>
                    <Form.Group>
                      <Form.Field style={{ width: '50%' }}>
                        <Input
                          style={{ width: '50%' }}
                          onChange={this.handleChange}
                          labelPosition="right"
                          label="Heures"
                          name="hour"
                          value={hour}
                        />{' '}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Submit label="Rechercher" onClick={this.handleSubmit} />
                    </Form.Group>
                    {tSearchedIntervenant && tSearchedIntervenant[0] ? (
                      <Form.Group>
                        Nom du patient : {tSearchedIntervenant[0].sPatientNomPrenom}
                      </Form.Group>
                    ) : null}
                  </Form>
                </Wrapper>
                <StyledList divided verticalAlign="middle">
                  {tSearchedIntervenant.length > 0 &&
                    tSearchedIntervenant.map(item => {
                      const colorAlowed = [
                        'blue',
                        'purple',
                        'brown',
                        'olive',
                        'black',
                        'lightblue',
                        'fuchsia',
                        'cyan',
                        'darkkhaki',
                        'moccasin'
                      ];
                      const filtre = intervenant.filter(i => i.iPKAnnuaire === item.iPKAnnuaire);
                      if (filtre.length === 0) {
                        intervenant.push(item);
                        const color = colorAlowed[intervenant.length - 1];
                        return (
                          <StyledEtatItemLeft
                            color={color}
                            key={`intervenant-list-${item.iPKAnnuaire}`}
                          >
                            <List.Content>
                              {item.sCodeAnnuaire} - {item.sNomPrenom}
                            </List.Content>
                            <BtnIsadomLeft icon href={`isadomlink://annuaire.${item.iPKAnnuaire}`}>
                              <Icon name="external alternate" />
                            </BtnIsadomLeft>
                          </StyledEtatItemLeft>
                        );
                      }
                      return null;
                    })}
                  {searched && tSearchedIntervenant.length === 0 && (
                    <List.Item>
                      <List.Content style={{ textAlign: 'center' }}>
                        Aucun intervenant trouvé
                      </List.Content>
                    </List.Item>
                  )}
                </StyledList>
              </Grid.Column>
              <Grid.Column width={13}>
                <AdsMap
                  searchIntervenant
                  googleApiKey={
                    process.env.REACT_APP_GOOGLE_API === 'true' && config.googleApiKey
                      ? config.googleApiKey
                      : ''
                  }
                  lat={
                    tSearchedIntervenant && tSearchedIntervenant[0]
                      ? tSearchedIntervenant[0].nPatientLat
                      : 47.546
                  }
                  lng={
                    tSearchedIntervenant && tSearchedIntervenant[0]
                      ? tSearchedIntervenant[0].nPatientLong
                      : 2.597
                  }
                  style={{ height: 650 }}
                  activeMarker={-1}
                  intervenant={
                    tSearchedIntervenant && tSearchedIntervenant[0]
                      ? tSearchedIntervenant[0].sPatientNomPrenom
                      : ''
                  }
                  intervenantMarker={
                    tSearchedIntervenant &&
                    tSearchedIntervenant[0] &&
                    tSearchedIntervenant[0].nPatientLat &&
                    tSearchedIntervenant[0].nPatientLong
                      ? [tSearchedIntervenant[0].nPatientLat, tSearchedIntervenant[0].nPatientLong]
                      : []
                  }
                  tMeetingMarkers={tSearchedIntervenant}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

LocateIntervenant.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  tSearchedIntervenant: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

LocateIntervenant.defaultProps = {
  tSearchedIntervenant: []
};

const mapStateToProps = state => ({
  tSearchedIntervenant: state.intervenant.search,
  config: state.config
});

export default connect(mapStateToProps)(LocateIntervenant);
