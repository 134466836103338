import React from 'react';
import PropTypes from 'prop-types';

import { List, Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const PaneWrapper = styled.div`
  height: ${props => (props.haveFilter ? 'calc(100% - 133px)' : '100%')};
  background: ${props => props.theme.colors.backgroundLight};
`;

const StyledList = styled(List)`
  margin: 0 !important;
  overflow: auto;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.white};
`;

const StyledEtatItem = styled(List.Item)`
  cursor: pointer;
  position: relative;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.tableRowEvenHover};
  }

  &:before {
    content: '';
    background-color: ${props => props.theme.colors[props.color]};
    height: 100%;
    top: 0;
    width: 10px;
    position: absolute;
  }

  & .content {
    width: 90%
    padding: 10px 20px !important;
  }
`;

const MeetingInfo = styled.div`
  margin-top: 5px;
`;

const RdvInfo = styled.div`
  display: flex;
  justify-content: start;
  margin: 0 0 10px;
  font-weight: bold;
`;

const RdvTime = styled.div`
  padding: 0 10px 0 0;
`;

const RdvTypeVisite = styled.div``;

const BtnIsadom = styled.a`
  position: absolute;
  top: 30px;
  right: 10px;
  margin: -20px 0 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;

  padding: 0 20px;
  height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  & i {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  & i.icon {
    margin: 0;
    padding: 0;
  }
`;

const MeetingAddress = styled.div`
  margin: 5px 0 0;
  font-size: 12px;
  text-transform: uppercase;
`;

const MeetingPhone = styled.div`
  margin-top: 5px;
  white-space: nowrap;

  & a {
    display: inline-block;
    margin: 0 10px 0 0;
    color: ${props => props.theme.colors.purple};
    letter-spacing: 1px;

    &: hover {
      color: ${props => props.theme.colors.purple};
      text-decoration: underline;
    }
  }
`;

const NoMeetingNotice = styled.div`
  padding: 20px 0;
  height: 100%;
  text-align: center;
  background: ${props => props.theme.colors.backgroundLight};
`;

const MeetingList = ({ tMeeting, selectMarker, isLoading }) => {
  return (
    <PaneWrapper haveFilter={tMeeting.length > 0}>
      {isLoading && <Loader active />}
      {!isLoading && tMeeting.length > 0 ? (
        <StyledList divided verticalAlign="middle">
          {tMeeting.map(item => {
            let color;
            if (item.bRdvAnnule) {
              color = 'red';
            } else if (item.bRdvTermine) {
              color = 'green';
            } else {
              color = 'grey';
            }
            return (
              <StyledEtatItem
                onMouseOver={() => selectMarker(item.iPKRdv)}
                onFocus={() => selectMarker(item.iPKRdv)}
                onMouseLeave={() => selectMarker(-1)}
                key={`meeting-list-${item.iPKRdv}`}
                color={color}
              >
                <List.Content>
                  <RdvInfo>
                    <RdvTime>{item.tRdvDebut}</RdvTime>
                    <RdvTypeVisite>
                      {item.sLibelle.split(', ').map(t => (
                        <div>- {t}</div>
                      ))}
                    </RdvTypeVisite>
                  </RdvInfo>
                  <MeetingInfo>
                    {item.sCodeAnnuaire} - {item.sNomPrenom}
                    <MeetingAddress>{item.sAdresseComplet}</MeetingAddress>
                  </MeetingInfo>
                  <MeetingPhone>
                    {item.sTelFixe && (
                      <span>
                        Fixe&nbsp;:&nbsp;
                        <a href={`tel:${item.sTelFixe}`}>{item.sTelFixe}</a>
                      </span>
                    )}
                    {item.sTelFixe !== item.sTelGsm && item.sTelGsm ? (
                      <span>
                        &nbsp;GSM&nbsp;:&nbsp;<a href={`tel:${item.sTelGsm}`}>{item.sTelGsm}</a>
                      </span>
                    ) : (
                      ''
                    )}
                    {item.sTelFixe !== item.sTelAutre &&
                    item.sTelGsm !== item.sTelAutre &&
                    item.sTelAutre ? (
                      <span>
                        &nbsp;Autre&nbsp;:&nbsp;
                        <a href={`tel:${item.sTelAutre}`}>{item.sTelAutre}</a>
                      </span>
                    ) : (
                      ''
                    )}
                  </MeetingPhone>
                </List.Content>
                <BtnIsadom href={`isadomlink://annuaire.${parseInt(item.iPKPatient, 0)}`}>
                  <Icon style={{ position: 'relative', left: '-6px' }} name="external alternate" />
                </BtnIsadom>
              </StyledEtatItem>
            );
          })}
        </StyledList>
      ) : (
        <NoMeetingNotice>
          {isLoading
            ? 'Chargement des rendez-vous en cours.'
            : 'Sélectionnez un intervenant pour afficher ses rendez-vous.'}
        </NoMeetingNotice>
      )}
    </PaneWrapper>
  );
};

MeetingList.propTypes = {
  tMeeting: PropTypes.arrayOf(PropTypes.object),
  selectMarker: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

MeetingList.defaultProps = {
  tMeeting: []
};

export default MeetingList;
