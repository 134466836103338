import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import { TransitionGroup, Transition } from 'react-transition-group';
import { handleScreenEnterAnimation, handleScreenExitAnimation } from './animations';

import Connexion from './screens/Connexion';
import NotFound from './screens/Erreur404';
import Planification from './screens/Planification';
import Deconnexion from './screens/Deconnexion';

const routes = [
  {
    component: Connexion,
    key: 'home',
    path: '/',
    id: 'home',
    title: 'Connexion',
    description: 'Pour accéder à votre compte, merci de saisir vos identifiants',
    exact: true,
    privateRoute: false,
    transitionAnimation: true
  },
  {
    component: Connexion,
    key: 'home',
    path: '/index.html',
    id: 'home',
    title: 'Connexion',
    description: 'Pour accéder à votre compte, merci de saisir vos identifiants',
    exact: true,
    privateRoute: false,
    transitionAnimation: true
  },
  {
    component: Connexion,
    key: 'connexion',
    path: '/connexion',
    id: 'home',
    title: 'Connexion',
    description: 'Pour accéder à votre compte, merci de saisir vos identifiants',
    exact: true,
    privateRoute: false,
    transitionAnimation: true
  },
  {
    component: Planification,
    key: 'planification',
    path: '/planification',
    id: 'planification',
    title: 'Planification des demandes',
    description: `Tableau de bord affichant la liste de demandes`,
    exact: true,
    privateRoute: true,
    transitionAnimation: true
  },
  {
    component: Deconnexion,
    key: 'deconnexion',
    path: '/deconnexion',
    id: 'home',
    title: 'Déconnexion',
    description: `Vous avez fermé votre session sur l'application AIDE`,
    exact: true,
    privateRoute: false,
    transitionAnimation: true
  }
];

const filterRoutes = location =>
  routes.filter(
    ({ path, privateRoute, exact, transitionAnimation }) =>
      !!matchPath(location.pathname, {
        path,
        privateRoute,
        exact,
        transitionAnimation
      })
  );

class Routes extends Component {
  selectTransitionAnimation = () => ({
    enter: handleScreenEnterAnimation,
    exit: handleScreenExitAnimation,
    delay: 1000
  });

  render() {
    const { isAuthenticated, animatedTransitions } = this.props;
    let anims = this.selectTransitionAnimation();

    return (
      <Switch>
        <Route
          render={({ location }) => {
            const selected = filterRoutes(location);
            if (selected.length === 0) {
              return (
                <TransitionGroup appear exit>
                  <Transition
                    key="404"
                    timeout={anims.delay}
                    onEnter={anims.enter}
                    onEntering={() => {}}
                    onEntered={() => {}}
                    onExit={anims.exit}
                    onExiting={() => {}}
                    onExited={() => {}}
                  >
                    <NotFound />
                  </Transition>
                </TransitionGroup>
              );
            }

            if (selected[0].privateRoute === true && !isAuthenticated) {
              return <Redirect to="/connexion" />;
            }

            if (
              location.pathname !== `${process.env.REACT_APP_BASE_URL}/planification` &&
              [
                `${process.env.REACT_APP_BASE_URL}/`,
                `${process.env.REACT_APP_BASE_URL}/index.html`
              ].indexOf(location.pathname) !== -1
            ) {
              return <Redirect to={`${process.env.REACT_APP_BASE_URL}/planification`} />;
            }

            if (animatedTransitions === false || selected[0].transitionAnimation === false) {
              anims = { enter: () => {}, exit: () => {}, delay: 0 };
            }

            if (animatedTransitions === true) {
              return (
                <TransitionGroup appear exit>
                  {selected.map(({ key, ...props }) => (
                    <Transition
                      key={`child-${key}`}
                      timeout={anims.delay}
                      onEnter={anims.enter}
                      onEntering={() => {}}
                      onEntered={() => {}}
                      onExit={anims.exit}
                      onExiting={() => {}}
                      onExited={() => {}}
                    >
                      <Suspense fallback={<div>Chargement...</div>}>
                        {React.createElement(
                          selected[0].component,
                          {
                            ...props,
                            location
                          },
                          null
                        )}
                      </Suspense>
                    </Transition>
                  ))}
                </TransitionGroup>
              );
            }

            return (
              <Suspense fallback={<div>Chargement...</div>}>
                {React.createElement(
                  selected[0].component,
                  {
                    location
                  },
                  null
                )}
              </Suspense>
            );
          }}
        />
      </Switch>
    );
  }
}

Routes.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  animatedTransitions: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired
};

Routes.defaultProps = {
  animatedTransitions: true
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated
});

export default withRouter(connect(mapStateToProps)(Routes));
