import {
  CHAT_LOAD_SUCCESS,
  CHAT_LOAD_FAILURE,
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_FAILURE,
  CHAT_READ_MESSAGE_SUCCESS,
  CHAT_UNREAD_MESSAGE_LOAD_FAILURE,
  CHAT_DESACTIVE_CONVERSATION_REQUEST
} from '../constants/ActionTypes';

const initialRequestState = {
  tConversation: []
};

export default (state = initialRequestState, { type, payload }) => {
  switch (type) {
    case CHAT_DESACTIVE_CONVERSATION_REQUEST: {
      const oConversation = state.tConversation.filter(
        i => i.iFKAnnAnnuaireIntervenant === payload.iAnnAnnuaireDestinataire
      )[0];
      oConversation.active = false;
      const tNewConversation = state.tConversation.filter(i =>
        i.iFKAnnAnnuaireSuperviseur === oConversation.iFKAnnAnnuaireSuperviseur ? oConversation : i
      );
      return { tConversation: tNewConversation };
    }
    case CHAT_LOAD_SUCCESS: {
      let tNewConversation = [];
      payload.map(conversation => {
        const oConversation = state.tConversation.filter(
          i => i.iFKAnnAnnuaireIntervenant === conversation.iFKAnnAnnuaireIntervenant
        )[0];
        if (oConversation) {
          oConversation.active = true;
          oConversation.tMessage = conversation.tMessage;
          tNewConversation = state.tConversation.filter(i =>
            i.iFKAnnAnnuaireIntervenant === oConversation.iFKAnnAnnuaireSuperviseur
              ? oConversation
              : i
          );
        } else {
          conversation.active = true;
          tNewConversation.push(conversation);
        }
        return null;
      });
      const tConversation = state.tConversation.concat(
        tNewConversation.filter(item => state.tConversation.indexOf(item) < 0)
      );
      return { tConversation };
    }
    case CHAT_LOAD_FAILURE: {
      return { ...state, error: payload };
    }
    case CHAT_UNREAD_MESSAGE_LOAD_FAILURE: {
      return { ...state, error: payload };
    }
    case CHAT_SEND_MESSAGE_SUCCESS: {
      const oConversation = state.tConversation.filter(
        i => i.iFKAnnAnnuaireIntervenant === payload.iFKAnnAnnuaireDestinataire
      )[0];
      oConversation.tMessage.push(payload);

      return {
        ...state,
        tConversation: state.tConversation.filter(i =>
          i.iFKAnnAnnuaireIntervenant === payload.iFKAnnAnnuaireDestinataire ? oConversation : i
        )
      };
    }
    case CHAT_READ_MESSAGE_SUCCESS: {
      let tNewConversation;
      payload.map(updatedMessage => {
        const oConversation = state.tConversation.filter(
          i => i.iFKAnnAnnuaireIntervenant === updatedMessage.iFKAnnAnnuaireAuteur
        )[0];
        const tUpdatedMessage = oConversation.tMessage.map(message =>
          message.iPKSmaMessage === updatedMessage.iPKSmaMessage ? updatedMessage : message
        );
        oConversation.tMessage = tUpdatedMessage;
        tNewConversation = state.tConversation.filter(i =>
          i.iFKAnnAnnuaireIntervenant === oConversation.iFKAnnAnnuaireSuperviseur
            ? oConversation
            : i
        );
        return null;
      });
      return { tConversation: tNewConversation };
    }
    case CHAT_SEND_MESSAGE_FAILURE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
};
