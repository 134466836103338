import { put } from 'redux-saga/effects';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  VIEW_ADD_SUCCESS,
  VIEW_ADD_FAILURE,
  VIEW_DELETE_SUCCESS,
  VIEW_DELETE_FAILURE,
  VIEW_ACTIVATE_SUCCESS,
  VIEW_ACTIVATE_FAILURE,
  VIEW_DEACTIVATE_SUCCESS
} from '../constants/ActionTypes';

export function* addView({ payload: { iPKAnnuaire, sLabel, tPos } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'view' } });

  try {
    yield put({
      type: VIEW_ADD_SUCCESS,
      payload: {
        iPKVue: iPKAnnuaire,
        iPKAnnuaire,
        sLabel,
        tPos
      }
    });
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de création de la vue';
        break;
      default:
        break;
    }

    yield put({ type: VIEW_ADD_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'view' } });
  }
}

export function* deleteView({ payload: { iPKVue } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'view' } });

  try {
    yield put({ type: VIEW_DELETE_SUCCESS, payload: { iPKVue } });
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de suppression de la vue';
        break;
      default:
        break;
    }

    yield put({ type: VIEW_DELETE_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'view' } });
  }
}

export function* activateView({ payload }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'view' } });

  try {
    yield put({
      type: VIEW_ACTIVATE_SUCCESS,
      payload
    });
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement de la vue';
        break;
      default:
        break;
    }

    yield put({ type: VIEW_ACTIVATE_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'view' } });
  }
}

export function* deactivateView() {
  yield put({ type: VIEW_DEACTIVATE_SUCCESS, payload: {} });
}
