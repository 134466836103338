import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  CHAT_LOAD_SUCCESS,
  CHAT_LOAD_FAILURE,
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_FAILURE,
  CHAT_RECEIVED_REQUEST,
  CHAT_RECEIVED_FAILURE,
  CHAT_READ_MESSAGE_SUCCESS,
  CHAT_READ_MESSAGE_FAILURE,
  CHAT_UNREAD_MESSAGE_LOAD_FAILURE
} from '../constants/ActionTypes';

export function* loadUnreadChat({ payload: { iAnnAnnuaireDestinataire } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`{
        loadAllChat(iAnnAnnuaireDestinataire: ${iAnnAnnuaireDestinataire}) {
          iPKSmaConversation
          iFKAnnAnnuaireIntervenant
          iFKAnnAnnuaireSuperviseur
          sNomPrenomIntervenant
          sNomPrenomSuperviseur
          tMessage {
            iPKSmaMessage
            iFKAnnAnnuaireAuteur
            iFKAnnAnnuaireDestinataire
            dhReception
            bRecu
            dhLecture
            bLu
            sTexteMessage
            dhEnvoi
            dhSuppression
            bSupprime
          }
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.loadAllChat;
    const requestsAno = [];
    requests.map(conversation => {
      requestsAno.push({
        iPKSmaConversation: conversation.iPKSmaConversation,
        iFKAnnAnnuaireSuperviseur: conversation.iFKAnnAnnuaireSuperviseur,
        iFKAnnAnnuaireIntervenant: conversation.iFKAnnAnnuaireIntervenant,
        sNomPrenomIntervenant: 'Intervenant ADS',
        sNomPrenomSuperviseur: 'Superviseur ADS',
        tMessage: conversation.tMessage
      });
      return null;
    });
    if (requests !== null) {
      yield put({
        type: CHAT_LOAD_SUCCESS,
        payload: process.env.REACT_APP_ANONYMOUS === 'true' ? requestsAno : requests
      });
    } else {
      yield put({
        type: CHAT_UNREAD_MESSAGE_LOAD_FAILURE,
        payload: 'Erreur de chargement des conversations'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: CHAT_UNREAD_MESSAGE_LOAD_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* loadChat({ payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`{
        loadChat(iAnnAnnuaireAuteur: ${iAnnAnnuaireAuteur}, iAnnAnnuaireDestinataire: ${iAnnAnnuaireDestinataire}) {
          iPKSmaConversation
          iFKAnnAnnuaireIntervenant
          iFKAnnAnnuaireSuperviseur
          sNomPrenomIntervenant
          sNomPrenomSuperviseur
          tMessage {
            iPKSmaMessage
            iFKAnnAnnuaireAuteur
            iFKAnnAnnuaireDestinataire
            dhReception
            bRecu
            dhLecture
            bLu
            sTexteMessage
            dhEnvoi
            dhSuppression
            bSupprime
          }
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.loadChat;
    const requestsAno = [];
    requests.map(conversation => {
      requestsAno.push({
        iFKAnnAnnuaireSuperviseur: conversation.iFKAnnAnnuaireSuperviseur,
        iFKAnnAnnuaireIntervenant: conversation.iFKAnnAnnuaireIntervenant,
        sNomPrenomIntervenant: 'Intervenant ADS',
        sNomPrenomSuperviseur: 'Superviseur ADS',
        tMessage: conversation.tMessage
      });
      return null;
    });
    if (requests !== null) {
      yield put({
        type: CHAT_RECEIVED_REQUEST,
        payload: {
          iAnnAnnuaireDestinataire: iAnnAnnuaireAuteur,
          iAnnAnnuaireAuteur: iAnnAnnuaireDestinataire
        }
      });
      yield put({
        type: CHAT_LOAD_SUCCESS,
        payload: process.env.REACT_APP_ANONYMOUS === 'true' ? requestsAno : requests
      });
    } else {
      yield put({
        type: CHAT_LOAD_FAILURE,
        payload: 'Erreur de chargement des conversations'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: CHAT_LOAD_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* sendMessage({
  payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire, sMessage }
}) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`mutation {
        sendMessage(
          iAnnAnnuaireAuteur: ${iAnnAnnuaireAuteur},
          iAnnAnnuaireDestinataire: ${iAnnAnnuaireDestinataire},
          sMessage: "${sMessage}"
        ) {
          iPKSmaMessage
          iFKAnnAnnuaireAuteur
          iFKAnnAnnuaireDestinataire
          dhReception
          bRecu
          dhLecture
          bLu
          sTexteMessage
          dhEnvoi
          dhSuppression
          bSupprime
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.sendMessage;
    if (requests !== null) {
      yield put({ type: CHAT_SEND_MESSAGE_SUCCESS, payload: requests });
    } else {
      yield put({
        type: CHAT_SEND_MESSAGE_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: CHAT_SEND_MESSAGE_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* receivedMessage({ payload: { iAnnAnnuaireDestinataire, iAnnAnnuaireAuteur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`mutation {
        receivedMessage(
          iAnnAnnuaireDestinataire: ${iAnnAnnuaireDestinataire}
          iAnnAnnuaireAuteur: ${iAnnAnnuaireAuteur}
        )
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.receivedMessage;
    if (requests === null) {
      yield put({
        type: CHAT_RECEIVED_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: CHAT_RECEIVED_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* readMessage({ payload: { iAnnAnnuaireDestinataire, iAnnAnnuaireAuteur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`mutation {
        readMessage(
          iAnnAnnuaireDestinataire: ${iAnnAnnuaireDestinataire}
          iAnnAnnuaireAuteur: ${iAnnAnnuaireAuteur}
        ) {
          iPKSmaMessage
          iFKAnnAnnuaireAuteur
          iFKAnnAnnuaireDestinataire
          dhReception
          bRecu
          dhLecture
          bLu
          sTexteMessage
          dhEnvoi
          dhSuppression
          bSupprime
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.readMessage;
    if (requests !== null && requests.length > 0) {
      yield put({ type: CHAT_READ_MESSAGE_SUCCESS, payload: requests });
    } else {
      yield put({
        type: CHAT_READ_MESSAGE_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: CHAT_READ_MESSAGE_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}
