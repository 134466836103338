import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';

import { fr } from 'date-fns/locale';

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

const WEEKDAYS_LONG = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

const Wrapper = styled.div`
  text-align: center;
`;

const PaneContent = styled.div`
  padding: 20px 10px;
  background: ${props => props.theme.colors.tableRowSelected};
`;

const PaneTitle = styled.h2`
  margin: 0 0 20px;
  font-family: 'Futura';
  font-size: 16px;
`;

const CustomGroup = styled(Form.Group)`
  display: inline-flex !important;
`;

class FilterDate extends Component {
  state = {
    startDate: new Date()
  };

  render() {
    const { onChange } = this.props;
    const { startDate } = this.state;
    return (
      <Wrapper>
        <PaneContent>
          <PaneTitle>Rechercher les rendez-vous par date</PaneTitle>
          <Form>
            <CustomGroup>
              <Form.Field>
                <DayPicker
                  showOutsideDays
                  locale={fr}
                  months={MONTHS}
                  weekdaysLong={WEEKDAYS_LONG}
                  weekdaysShort={WEEKDAYS_SHORT}
                  firstDayOfWeek={1}
                  mode="single"
                  selected={startDate}
                  onSelect={date => {
                    this.setState({ startDate: date });
                    onChange(
                      `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
                        -2
                      )}-${`0${date.getDate()}`.slice(-2)}`
                    );
                  }}
                  modifiersClassNames={{
                    selected: 'smart-selected',
                    today: 'smart-today'
                  }}
                />
                {/* <DatePicker
                  selected={startDate || new Date()}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => {
                    this.setState({ startDate: date });
                    onChange(
                      `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
                        -2
                      )}-${`0${date.getDate()}`.slice(-2)}`
                    );
                  }}
                /> */}
              </Form.Field>
            </CustomGroup>
          </Form>
        </PaneContent>
      </Wrapper>
    );
  }
}

FilterDate.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default FilterDate;
