import {
  CHAT_LOAD_REQUEST,
  CHAT_SEND_MESSAGE_REQUEST,
  CHAT_RECEIVED_REQUEST,
  CHAT_READ_MESSAGE_REQUEST,
  CHAT_UNREAD_MESSAGE_LOAD_REQUEST,
  CHAT_DESACTIVE_CONVERSATION_REQUEST
} from '../constants/ActionTypes';

export const loadChat = (iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire) => ({
  type: CHAT_LOAD_REQUEST,
  payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire }
});

export const desactiveConversation = iAnnAnnuaireDestinataire => ({
  type: CHAT_DESACTIVE_CONVERSATION_REQUEST,
  payload: { iAnnAnnuaireDestinataire }
});

export const loadUnreadChat = iAnnAnnuaireDestinataire => ({
  type: CHAT_UNREAD_MESSAGE_LOAD_REQUEST,
  payload: { iAnnAnnuaireDestinataire }
});

export const sendMessage = (iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire, sMessage) => ({
  type: CHAT_SEND_MESSAGE_REQUEST,
  payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire, sMessage }
});

export const receivedMessage = (iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire) => ({
  type: CHAT_RECEIVED_REQUEST,
  payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire }
});

export const readMessage = (iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire) => ({
  type: CHAT_READ_MESSAGE_REQUEST,
  payload: { iAnnAnnuaireAuteur, iAnnAnnuaireDestinataire }
});
