import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const Btn = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1000;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: ${props => props.theme.colors.grey};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  & i {
    margin: 0 !important;
    color: ${props => props.theme.colors.textBlack};
  }

  &:hover {
    background: ${props => props.theme.colors.grey};

    & i {
      color: ${props => props.theme.colors.blueDark};
    }
  }
`;

const Toggle = ({ opened, onClick }) => {
  return (
    <Btn onClick={onClick}>
      <Icon name={opened ? 'angle double left' : 'angle double right'} size="large" />
    </Btn>
  );
};

Toggle.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Toggle;
