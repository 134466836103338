import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledList = styled(List)`
  margin: 0 !important;
  position: relative;
  top: 317px;
  overflow: auto;
  height: calc(100% - 317px);
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`;

const StyledListItem = styled(List.Item)`
  padding: 10px 10px !important;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.tableRowEvenHover};
  }
`;

const IntervenantList = ({ tIntervenant, selectIntervenant }) => {
  return (
    <StyledList divided verticalAlign="middle">
      {_.uniqBy(tIntervenant, 'iPKAnnuaire').map(item => {
        return (
          <StyledListItem
            key={`intervenant-list-${item.iPKAnnuaire}`}
            onClick={() => selectIntervenant(item)}
          >
            <List.Content>
              {item.sNomPrenom} <br />
              {item.iNbRDV ? `Nombre de rendez-vous: ${item.iNbRDV}` : 'Aucun rendez-vous prévu.'}
            </List.Content>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

IntervenantList.propTypes = {
  tIntervenant: PropTypes.arrayOf(PropTypes.object),
  selectIntervenant: PropTypes.func.isRequired
};

IntervenantList.defaultProps = {
  tIntervenant: []
};

export default IntervenantList;
