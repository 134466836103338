import {
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILURE
} from '../constants/ActionTypes';

const initialAuthState = {
  isAuthenticated: false,
  data: {
    iPKUtilisateur: 0,
    iFKAnnuaire: 0,
    iRole: 3,
    sCodeCivilite: '',
    sNom: 'Invité',
    sPrenom: 'Invité',
    iFKAntenne: 0,
    date: new Date()
  },
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case USER_SIGNIN_SUCCESS: {
      const data = {
        iPKUtilisateur: payload.iPKUtilisateur,
        iFKAnnuaire: payload.iFKAnnuaire,
        iRole: payload.iRole,
        sCodeCivilite: payload.sCodeCivilite || '',
        sNom: payload.sNom || '',
        sPrenom: payload.sPrenom || '',
        date: payload.dDate
      };
      return { ...state, isAuthenticated: true, data, error: null };
    }
    case USER_SIGNIN_FAILURE: {
      return { error: payload };
    }
    case USER_SIGNOUT_SUCCESS: {
      return {
        isAuthenticated: false,
        data: { id: 0, name: 'GUEST', short: 'GUEST', date: new Date() },
        error: null,
        profile: payload
      };
    }
    case USER_SIGNOUT_FAILURE: {
      return { error: payload };
    }
    default:
      return state;
  }
};
