import {
  VIEW_LIST_SUCCESS,
  VIEW_LIST_FAILURE,
  VIEW_ADD_SUCCESS,
  VIEW_ADD_FAILURE,
  VIEW_DELETE_SUCCESS,
  VIEW_DELETE_FAILURE,
  VIEW_ACTIVATE_SUCCESS,
  VIEW_ACTIVATE_FAILURE,
  VIEW_DEACTIVATE_SUCCESS,
  VIEW_DEACTIVATE_FAILURE
} from '../constants/ActionTypes';

const initialViewState = {
  tVue: [],
  selected: -1,
  error: null
};

export default (state = initialViewState, { type, payload }) => {
  switch (type) {
    case VIEW_LIST_SUCCESS: {
      return { ...state, tVue: payload, error: null };
    }
    case VIEW_LIST_FAILURE: {
      return { tVue: [], error: payload };
    }

    case VIEW_ADD_SUCCESS: {
      return { ...state, tVue: [...state.tVue, payload], error: null };
    }
    case VIEW_ADD_FAILURE: {
      return { ...state, error: payload };
    }

    case VIEW_DELETE_SUCCESS: {
      const tVue = state.tVue.filter(view => view.iPKVue !== payload.iPKVue);
      return { ...state, tVue, error: null };
    }
    case VIEW_DELETE_FAILURE: {
      return { ...state, error: payload };
    }

    case VIEW_ACTIVATE_SUCCESS: {
      return { ...state, selected: payload, error: null };
    }
    case VIEW_ACTIVATE_FAILURE: {
      return { ...state, selected: -1, error: payload };
    }

    case VIEW_DEACTIVATE_SUCCESS: {
      return { ...state, selected: -24, error: null };
    }
    case VIEW_DEACTIVATE_FAILURE: {
      return { ...state, selected: -24, error: payload };
    }

    default:
      return state;
  }
};
