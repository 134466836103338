import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Leaflet from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import CanvasLayer from 'react-leaflet-canvas-layer';
import Control from 'react-leaflet-control';
import GoogleLayer from './GoogleLayer';
import markerPos from '../../assets/img/marker-pos-icon.svg';
import smallMarker from '../../assets/img/marker-icon-small.svg';
import 'leaflet/dist/leaflet.css';

const intervenantIcon = Leaflet.icon({
  iconUrl: markerPos,
  className: 'main-icon'
});

const StyledMap = styled(Map)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

const StyledPopup = styled(Popup)`
  left: -80px !important;
  width: 165px;
  bottom: -2px !important;
`;

const StyledMiniMarker = styled.span`
  content: '';
  mask: url(${smallMarker}) no-repeat center;
  height: 25px !important;
  width: 16px !important;
  background-color: ${props => props.color};
  display: inline-block;
  vertical-align: middle;
`;

const StyledUl = styled.ul`
  padding: 15px;
  background: rgba(178, 179, 179, 0.5);
`;

const StyledLi = styled.li`
  list-style: none;
  line-height: 35px;
  font-weight: bold;
`;

const drawMethod = info => {
  const ctx = info.canvas.getContext('2d');
  ctx.clearRect(0, 0, info.canvas.width, info.canvas.height);
  ctx.fillStyle = 'rgba(255,116,0, 0.2)';
  const point = info.map.latLngToContainerPoint([-37, 175]);
  ctx.beginPath();
  ctx.arc(point.x, point.y, 200, 0, Math.PI * 2.0, true, true);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
};

const AdsMap = ({
  lat,
  lng,
  googleApiKey,
  intervenant,
  intervenantMarker,
  tMeetingMarkers,
  activeMarker,
  style,
  searchIntervenant
}) => {
  let position = [[lat, lng], [lat, -lng]];

  const latLngBounds = [];
  const tIntervenant = [];
  let backgroundColor;
  if (typeof tMeetingMarkers === 'object') {
    tMeetingMarkers
      .filter(
        meeting =>
          meeting.nLatitude !== null &&
          meeting.nLongitude !== null &&
          meeting.nLatitude !== 0 &&
          meeting.nLongitude !== 0
      )
      .map(meeting => latLngBounds.push([meeting.nLatitude, meeting.nLongitude]));
  }
  if (latLngBounds.length === 1) {
    position = [
      [latLngBounds[0][0] - 0.1, latLngBounds[0][1] - 0.1],
      [latLngBounds[0][0], latLngBounds[0][1]],
      [latLngBounds[0][0] + 0.1, latLngBounds[0][1] + 0.1]
    ];
  }
  if (searchIntervenant && intervenantMarker.length) {
    latLngBounds.push(intervenantMarker);
  }
  if (latLngBounds.length > 1) {
    latLngBounds.push([latLngBounds[0][0] + 0.1, latLngBounds[0][1] + 0.1]);
  }

  // console.log(latLngBounds);

  return (
    <StyledMap style={style} bounds={latLngBounds.length > 1 ? latLngBounds : position}>
      {googleApiKey ? (
        <GoogleLayer googlekey={googleApiKey} maptype="ROADMAP" />
      ) : (
        <>
          <CanvasLayer drawMethod={drawMethod} />
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </>
      )}

      {intervenantMarker.length > 0 ? (
        <Marker icon={intervenantIcon} position={intervenantMarker}>
          <StyledPopup>{intervenant}</StyledPopup>
        </Marker>
      ) : (
        ''
      )}
      {typeof tMeetingMarkers === 'object' &&
        tMeetingMarkers
          .filter(
            meeting =>
              meeting.nLatitude !== null &&
              meeting.nLongitude !== null &&
              meeting.nLatitude !== 0 &&
              meeting.nLongitude !== 0
          )
          .map(meeting => {
            let className;
            const colorAlowed = [
              'blue',
              'purple',
              'brown',
              'olive',
              'black',
              'lightblue',
              'fuchsia',
              'cyan',
              'darkkhaki',
              'moccasin'
            ];
            const filtre = tIntervenant.filter(i => i.iPKAnnuaire === meeting.iPKAnnuaire);
            if (filtre.length === 0 && searchIntervenant) {
              tIntervenant.push(meeting);
              backgroundColor = colorAlowed[tIntervenant.length - 1];
            }
            if (!searchIntervenant) {
              backgroundColor = 'rgb(161, 13, 89)';
              if (meeting.bRdvTermine) {
                backgroundColor = 'rgb(62, 167, 28)';
              }
              if (meeting.bRdvAnnule) {
                backgroundColor = 'rgb(88, 88, 88)';
              }
              if (meeting.iPKRdv === activeMarker) {
                className += ' bounce';
              }
            }
            const icon = Leaflet.divIcon({
              className,
              html: `<div class="map-label" style="background:${backgroundColor}"></div>`
            });

            return (
              <Marker
                key={`margin-${meeting.iPKRdv}`}
                icon={icon}
                active={activeMarker === meeting.iPKRdv}
                position={meeting.nLatitude ? [meeting.nLatitude, meeting.nLongitude] : []}
              >
                <StyledPopup>
                  {meeting.sCodeAnnuaire} <br />
                  {meeting.sNomPrenom} <br />
                  Rendez-vous à {meeting.tRdvDebut}
                </StyledPopup>
              </Marker>
            );
          })}
      {!searchIntervenant ? (
        <Control position="topright">
          <StyledUl>
            <StyledLi>
              <StyledMiniMarker color="rgb(161, 13, 89)" /> Rendez-vous à faire
            </StyledLi>
            <StyledLi>
              <StyledMiniMarker color="rgb(62, 167, 28)" /> Rendez-vous fait
            </StyledLi>
            <StyledLi>
              <StyledMiniMarker color="rgb(88, 88, 88)" /> Rendez-vous annulé
            </StyledLi>
          </StyledUl>
        </Control>
      ) : null}
    </StyledMap>
  );
};

AdsMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  googleApiKey: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  activeMarker: PropTypes.number.isRequired,
  intervenant: PropTypes.string.isRequired,
  tMeetingMarkers: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervenantMarker: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchIntervenant: PropTypes.bool
};

AdsMap.defaultProps = {
  searchIntervenant: true,
  style: {}
};

export default AdsMap;
