import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.h1`
  margin: 0 0 15px;
  font-size: 1.4em;
  color: ${props => props.theme.colors.purple};
`;

const H1 = ({ children }) => {
  return <Title>{children}</Title>;
};

H1.propTypes = {
  children: PropTypes.string.isRequired
};

export default H1;
