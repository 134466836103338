import { all, takeLatest } from 'redux-saga/effects';
import { signUserIn, signUserOut, setUserLogged, setUserUnlogged } from './sagas/user';
import { showLoading, hideLoading } from './sagas/loadings';
import { loadConfig } from './sagas/config';
import { addView, activateView, deleteView, deactivateView } from './sagas/view';
import {
  loadIntervenants,
  loadIntervenantMeeting,
  loadIntervenantsByPatient
} from './sagas/intervenant';
import { loadChat, loadUnreadChat, sendMessage, receivedMessage, readMessage } from './sagas/chat';
import {
  LOADING_ACTIVATE_REQUEST,
  LOADING_DEACTIVATE_REQUEST,
  CONFIG_LOAD_REQUEST,
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT_REQUEST,
  VIEW_ADD_REQUEST,
  VIEW_ACTIVATE_REQUEST,
  VIEW_DEACTIVATE_REQUEST,
  VIEW_DELETE_REQUEST,
  INTERVENANT_LOAD_REQUEST,
  INTERVENANT_MEETING_LOAD_REQUEST,
  INTERVENANT_PATIENT_REQUEST,
  CHAT_LOAD_REQUEST,
  CHAT_UNREAD_MESSAGE_LOAD_REQUEST,
  CHAT_SEND_MESSAGE_REQUEST,
  CHAT_RECEIVED_REQUEST,
  CHAT_READ_MESSAGE_REQUEST,
  USER_LOGGED_REQUEST,
  USER_UNLOGGED_REQUEST
} from './constants/ActionTypes';

export default function* rootSaga() {
  yield all([
    takeLatest(LOADING_ACTIVATE_REQUEST, showLoading),
    takeLatest(LOADING_DEACTIVATE_REQUEST, hideLoading),
    takeLatest(CONFIG_LOAD_REQUEST, loadConfig),
    takeLatest(USER_SIGNIN_REQUEST, signUserIn),
    takeLatest(USER_SIGNOUT_REQUEST, signUserOut),
    takeLatest(VIEW_ADD_REQUEST, addView),
    takeLatest(VIEW_ACTIVATE_REQUEST, activateView),
    takeLatest(VIEW_DEACTIVATE_REQUEST, deactivateView),
    takeLatest(VIEW_DELETE_REQUEST, deleteView),
    takeLatest(INTERVENANT_LOAD_REQUEST, loadIntervenants),
    takeLatest(INTERVENANT_MEETING_LOAD_REQUEST, loadIntervenantMeeting),
    takeLatest(INTERVENANT_PATIENT_REQUEST, loadIntervenantsByPatient),
    takeLatest(CHAT_LOAD_REQUEST, loadChat),
    takeLatest(CHAT_SEND_MESSAGE_REQUEST, sendMessage),
    takeLatest(CHAT_RECEIVED_REQUEST, receivedMessage),
    takeLatest(CHAT_READ_MESSAGE_REQUEST, readMessage),
    takeLatest(CHAT_UNREAD_MESSAGE_LOAD_REQUEST, loadUnreadChat),
    takeLatest(USER_LOGGED_REQUEST, setUserLogged),
    takeLatest(USER_UNLOGGED_REQUEST, setUserUnlogged)
  ]);
}
