import {
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT_REQUEST,
  USER_UNLOGGED_REQUEST
} from '../constants/ActionTypes';

export const signUserIn = (sLogin, sPassword) => ({
  type: USER_SIGNIN_REQUEST,
  payload: { sLogin, sPassword }
});

export const signUserOut = () => ({
  type: USER_SIGNOUT_REQUEST,
  payload: { token: null, error: null }
});

export const setUnloggedUser = iPKAnnuaire => ({
  type: USER_UNLOGGED_REQUEST,
  payload: { iPKAnnuaire }
});
