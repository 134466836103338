import { combineReducers } from 'redux';

import { USER_SIGNOUT_SUCCESS } from './constants/ActionTypes';

import config from './reducers/config';
import loadings from './reducers/loadings';
import user from './reducers/user';
import view from './reducers/view';
import intervenant from './reducers/intervenant';
import chat from './reducers/chat';

const appReducer = combineReducers({
  config,
  loadings,
  user,
  intervenant,
  view,
  chat
});

export default (state, action) => {
  let currentState = state;

  if (action.type === USER_SIGNOUT_SUCCESS) {
    currentState = undefined;
  }

  return appReducer(currentState, action);
};
