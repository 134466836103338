import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styled from 'styled-components';

import { deleteView, activateView } from '../../../../../actions/view';
import { loadIntervenantMeeting } from '../../../../../actions/intervenant';

import ViewItem from './Item';

const StyledViewList = styled.ul`
  margin: 0;
  padding: 0;
`;

class ViewList extends Component {
  onClick = view => {
    const { dispatch, selected, date } = this.props;
    if (selected.iPKAnnuaire !== view.iPKVue) {
      dispatch(activateView(view));
      dispatch(loadIntervenantMeeting(view.iPKVue, date));
    }
  };

  onClose = (evt, iPKVue) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { dispatch } = this.props;
    dispatch(deleteView(iPKVue));
  };

  render() {
    const { views, selected } = this.props;

    return (
      <StyledViewList>
        {views.map(view => (
          <ViewItem
            key={`tabs-${view.iPKVue}`}
            id={view.iPKVue}
            label={view.sLabel}
            tMeetingPos={view.tMeetingPos}
            selected={selected.iPKAnnuaire === view.iPKAnnuaire}
            closable={view.iPKVue > 0}
            onClose={this.onClose}
            onClick={evt => {
              evt.preventDefault();
              this.onClick(view);
            }}
          />
        ))}
      </StyledViewList>
    );
  }
}

ViewList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  selected: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.number]).isRequired
};

const mapStateToProps = state => ({
  views: state.view.tVue || [],
  selected: state.view.selected
});

export default connect(mapStateToProps)(ViewList);
