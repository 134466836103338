import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  INTERVENANT_LOAD_SUCCESS,
  INTERVENANT_LOAD_FAILURE,
  INTERVENANT_PATIENT_SUCCESS,
  INTERVENANT_PATIENT_FAILURE,
  INTERVENANT_MEETING_LOAD_SUCCESS,
  INTERVENANT_MEETING_LOAD_FAILURE
} from '../constants/ActionTypes';

export function* loadIntervenants({ payload: { oFilters } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`{
        loadIntervenants(sFilters: "${JSON.stringify(JSON.stringify(oFilters)).slice(1, -1)}") {
          iPKAnnuaire
          sNomPrenom
          sCodeAnnuaire
          rLatitude
          rLongitude
          iNbRDV
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.loadIntervenants;
    const requestsAno = [];
    requests.map(intervenant => {
      requestsAno.push({
        iPKAnnuaire: intervenant.iPKAnnuaire,
        sNomPrenom: 'Intervenant ADS Test',
        sCodeAnnuaire: 'ACN',
        rLatitude: intervenant.rLatitude,
        rLongitude: intervenant.rLongitude,
        iNbRDV: intervenant.iNbRDV
      });
      return null;
    });
    if (requests !== null) {
      yield put({
        type: INTERVENANT_LOAD_SUCCESS,
        payload: process.env.REACT_APP_ANONYMOUS === 'true' ? requestsAno : requests
      });
    } else {
      yield put({ type: INTERVENANT_LOAD_FAILURE, payload: 'Erreur de chargement des demandes' });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: INTERVENANT_LOAD_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* loadIntervenantsByPatient({ payload: { iCodePatient, hour } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  try {
    const LOAD_REQUEST = gql`{
        loadIntervenantsByPatient(iCodePatient: "${iCodePatient}", iHour: ${hour}) {
          iPKAnnuaire
          sCodeAnnuaire
          sNomPrenom
          iPKRdv
          tRdvDebut
          nLatitude
          nLongitude
          iPatientCode
          nPatientLat
          nPatientLong
          sPatientNomPrenom
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.loadIntervenantsByPatient;
    const requestsAno = [];
    requests.map(patient => {
      requestsAno.push({
        iPKAnnuaire: patient.iPKAnnuaire,
        sCodeAnnuaire: 'ADS',
        sNomPrenom: 'Intervenant ADS test',
        iPKRdv: patient.iPKRdv,
        tRdvDebut: patient.tRdvDebut,
        nLatitude: patient.nLatitude,
        nLongitude: patient.nLongitude,
        iPatientCode: patient.iPatientCode,
        nPatientLat: patient.nPatientLat,
        nPatientLong: patient.nPatientLong,
        sPatientNomPrenom: 'Patient ADS'
      });
      return null;
    });
    if (requests !== null) {
      yield put({
        type: INTERVENANT_PATIENT_SUCCESS,
        payload: process.env.REACT_APP_ANONYMOUS === 'true' ? requestsAno : requests
      });
    } else {
      yield put({
        type: INTERVENANT_PATIENT_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: INTERVENANT_PATIENT_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
  }
}

export function* loadIntervenantMeeting({ payload: { iPKAnnuaire, date } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'meetingList' } });

  try {
    const LOAD_REQUEST = gql`{
        loadIntervenantMeetings(iPKAnnuaire: ${iPKAnnuaire}, date: "${date}") {
          iPKAnnuaire
          iPKPatient
          iPKRdv
          sCodeAnnuaire
          sNomPrenom
          tRdvDebut
          bRdvAnnule
          bRdvTermine
          sAdresseComplet
          sTelFixe
          sTelAutre
          sTelGsm
          nLatitude
          nLongitude
          sLibelle
        }
      }`;
    const response = yield call(fetchApollo, LOAD_REQUEST);
    const requests = response.data.loadIntervenantMeetings;
    const requestsAno = [];
    requests.map(meeting => {
      requestsAno.push({
        bRdvAnnule: meeting.bRdvAnnule,
        bRdvTermine: meeting.bRdvTermine,
        iPKAnnuaire: meeting.iPKAnnuaire,
        iPKPatient: meeting.iPKPatient,
        iPKRdv: meeting.iPKRdv,
        nLatitude: meeting.nLatitude,
        nLongitude: meeting.nLongitude,
        sAdresseComplet: '12 Avenue Charles de Gaulle, 31130 Balma',
        sCodeAnnuaire: meeting.sCodeAnnuaire,
        sLibelle: meeting.sLibelle,
        sNomPrenom: 'ADS Test',
        sTelAutre: '0606060601',
        sTelFixe: '0404040404',
        sTelGsm: '0606060606',
        tRdvDebut: meeting.tRdvDebut
      });
      return null;
    });

    if (requests !== null) {
      yield put({
        type: INTERVENANT_MEETING_LOAD_SUCCESS,
        payload: process.env.REACT_APP_ANONYMOUS === 'true' ? requestsAno : requests
      });
    } else {
      yield put({
        type: INTERVENANT_MEETING_LOAD_FAILURE,
        payload: 'Erreur de chargement des demandes'
      });
    }
  } catch (error) {
    let message = { error };

    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 404:
        message = 'Page not found';
        break;
      case 401:
        message = 'Erreur de chargement des demandes';
        break;
      default:
        break;
    }

    yield put({ type: INTERVENANT_MEETING_LOAD_FAILURE, payload: message });
  } finally {
    yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'meetingList' } });
  }
}
