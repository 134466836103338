import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BtnSubmit = styled.button`
  padding: 0 20px;
  height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  & i {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const Submit = ({ className, label, modified, onClick }) => {
  return (
    <BtnSubmit className={className} modified={modified} onClick={onClick}>
      {label}
    </BtnSubmit>
  );
};

Submit.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  modified: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Submit.defaultProps = {
  className: '',
  modified: false
};

export default Submit;
