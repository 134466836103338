import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';
import map from '../../../../../assets/img/map.svg';

const StyledViewItem = styled.li`
  position: relative;
  margin: 0 5px 5px 0;
  display: inline-block;
  padding: ${props => (props.closable ? '0 30px 0 5px' : '0 5px')};
  height: 40px;
  line-height: 40px;
  list-style: none;

  font-family: 'Futura';
  color: ${props => props.theme.colors.white};
  background: ${props =>
    props.selected ? props.theme.colors.blueDark : props.theme.colors.inputBorderDark};
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  &:hover {
    background: ${props =>
      props.selected ? props.theme.colors.blueDark : props.theme.colors.greyADOM};
  }

  img {
    margin-right: 10px;
    position: relative;
    top: 3px;
    left: 5px;
  }
`;

const BtnViewItemClose = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  text-align: center;
  background: transparent;
  border: 0;
  outline: none;
  transition: all ease-in-out 0.25s
  color: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }

  & i {
    margin: 0;
  }
`;

class ViewItem extends Component {
  onBtnCloseClick = evt => {
    const { onClose, id } = this.props;
    onClose(evt, id);
  };

  render() {
    const { label, selected, closable, onClick } = this.props;
    return (
      <StyledViewItem selected={selected} closable={closable} onClick={onClick}>
        {closable && (
          <BtnViewItemClose onClick={this.onBtnCloseClick}>
            <Icon name="close" />
          </BtnViewItemClose>
        )}
        <img width="20" src={map} alt="map" />
        {label}
      </StyledViewItem>
    );
  }
}

ViewItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default connect(null)(ViewItem);
