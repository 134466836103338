import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, Radio, Select, Input } from 'semantic-ui-react';

import { loadIntervenants } from '../../../../../actions/intervenant';

import Submit from '../../Btn/Submit';

const Wrapper = styled.div`
  position: fixed;
  top: 40px;
  width: 300px;
  z-index: 999;
`;

const Separator = styled.div`
  margin: 5px 10px 20px;
  background: #fff;
  height: 1px;
  overflow: hidden;
`;

const PaneContent = styled.div`
  padding: 20px 10px;
  background: ${props => props.theme.colors.tableRowSelected};
`;

const PaneTitle = styled.h2`
  margin: 0 0 20px;
  font-family: 'Futura';
  font-size: 16px;
`;

const SelectBox = styled(Select)`
  min-width: initial !important;
`;

const InlineFormGroup = styled(Form.Group)`
  align-items: center;
`;

const Label = styled.label`
  display: inline-block;
  color: rgba(0, 0, 0, 0.95) !important;
`;

const FilterSubmit = styled(Submit)`
  padding: 0 10px;
  width: 100%;
`;

class FilterPane extends Component {
  state = {
    rangeType: 'hour',
    range: 1,
    connected: false,
    withRdv: true,
    antenne: -1,
    antenneOptions: [{ key: -1, text: 'Toutes les antennes', value: -1 }]
  };

  static getDerivedStateFromProps(nextProps) {
    const { tAntenne } = nextProps;

    const antenneOptions = [{ key: -1, text: 'Toutes les antennes', value: -1 }].concat(
      tAntenne.map(({ iPKAnnuaire, sNom }) => ({
        key: iPKAnnuaire,
        text: sNom,
        value: iPKAnnuaire
      }))
    );

    return { antenneOptions };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadIntervenants(this.state));
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    dispatch(loadIntervenants(this.state));
  }

  handleChangeRadio = (e, { name, checked }) => {
    this.setState({ [name]: checked });
  };

  handleChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { opened, handleOpen } = this.props;
    const { rangeType, range, connected, withRdv, antenneOptions, antenne } = this.state;
    return (
      <Wrapper>
        {opened && (
          <PaneContent>
            <PaneTitle>Rechercher un intervenant</PaneTitle>
            <Form>
              <Form.Group>
                <Form.Field>
                  <Select
                    label="Antenne"
                    name="antenne"
                    value={antenne}
                    options={antenneOptions}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    toggle
                    checked={withRdv}
                    onChange={this.handleChangeRadio}
                    label="Avec RDV"
                    name="withRdv"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    toggle
                    checked={connected}
                    label="Connecté"
                    name="connected"
                    onChange={this.handleChangeRadio}
                  />
                </Form.Field>
              </Form.Group>
              <InlineFormGroup>
                <Label htmlFor="range">Depuis moins de</Label>
                <Form.Field width={4}>
                  <Input name="range" value={range} onChange={this.handleChange} />
                </Form.Field>
                <Form.Field width={6}>
                  <SelectBox
                    name="rangeType"
                    value={rangeType}
                    options={[
                      { key: 'heure', text: 'Heure(s)', value: 'hour' },
                      { key: 'minute', text: 'Minute(s)', value: 'min' }
                    ]}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </InlineFormGroup>
            </Form>
            <Separator />
            <FilterSubmit label="Localiser un intervenant près d'un patient" onClick={handleOpen} />
          </PaneContent>
        )}
      </Wrapper>
    );
  }
}

FilterPane.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.any).isRequired
  // onBtnToggleFilterPaneClick: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    tAntenne: state.config.tAntenne
  };
};

export default connect(mapStateToProps, null)(FilterPane);
