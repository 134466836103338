import {
  INTERVENANT_LOAD_REQUEST,
  INTERVENANT_MEETING_LOAD_REQUEST,
  INTERVENANT_PATIENT_REQUEST
} from '../constants/ActionTypes';

export const loadIntervenants = oFilters => ({
  type: INTERVENANT_LOAD_REQUEST,
  payload: { oFilters }
});

export const loadIntervenantMeeting = (iPKAnnuaire, date) => ({
  type: INTERVENANT_MEETING_LOAD_REQUEST,
  payload: { iPKAnnuaire, date }
});

export const loadIntervenantsByPatient = (iCodePatient, hour) => ({
  type: INTERVENANT_PATIENT_REQUEST,
  payload: { iCodePatient, hour }
});
